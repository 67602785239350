import { ActionTree } from 'vuex'

type GState = {
  layout?: any
  menu?: any
}

export const state = (): GState => ({})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {}
