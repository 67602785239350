import Vue from 'vue'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const d = dayjs.extend(utc).extend(timezone)

Vue.filter('toDate', function (value) {
  if (typeof value !== 'string') {
    return value
  }
  return d.tz(value, 'Europe/Rome').format('DD/MM/YYYY')
})

Vue.filter('toDateWithMinutes', function (value) {
  if (typeof value !== 'string') {
    return value
  }
  return d.tz(value, 'Europe/Rome').format('DD/MM/YYYY HH:mm')
})

Vue.filter('toDay', function (value) {
  return d.tz(value, 'Europe/Rome').format('DD')
})

Vue.filter('toMonth', function (value) {
  return itaMonth[d.tz(value, 'Europe/Rome').month()]
})

const itaMonth = {
  0: 'Gen',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'Mag',
  5: 'Giu',
  6: 'Lug',
  7: 'Ago',
  8: 'Set',
  9: 'Ott',
  10: 'Nov',
  11: 'Dic',
}
