import { Component } from 'nuxt-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import Validator from './validators'

@Component
export default class NewsletterValidator extends Validator {
  @Validations()
  validations = {
    form: {
      email: { required, email },
      privacy: {
        sameAs: sameAs(() => true),
      },
    },
  }
}
