import { Vue, Component } from 'nuxt-property-decorator'
import Vuelidate from 'vuelidate'

// @ts-ignore
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'

Vue.use(Vuelidate)
Vue.component('FormError', templates.singleErrorExtractor.foundation6)
Vue.use(vuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.foundation6,
  messages: {
    required: 'Obbligatorio',
    email: 'Formato email non valido',
    sameAs: 'Deve essere accettato',
  },
})

@Component({})
export default class Validators extends Vue {
  public errors: string[] = []
  public success: boolean = false

  public setErrors(error: any) {
    if (Array.isArray(error)) {
      this.errors = [...error[0]]
    } else {
      for (const [key, value] of Object.entries(error)) {
        if (Array.isArray(value)) {
          this.errors.push(value[0])
        }
      }
    }
  }

  public resetErrors() {
    this.errors = []
  }
}
