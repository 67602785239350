
import { Component, Vue, Prop, Getter } from 'nuxt-property-decorator'

@Component
export default class TheHeader extends Vue {
  @Prop({ default: 'right-8' }) right!: string
  @Prop({ default: 'top-11' }) top!: string
  @Prop({ default: 'bg-dark' }) bgColor!: string
  @Getter('menu/opened') isMobileMenuOpened!: boolean

  public toggleMenu(): void {
    this.$store.dispatch('menu/toggleOpen')
  }
}
