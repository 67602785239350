import { ActionTree } from 'vuex'
import { RootState } from '~/store'
import { MenuState } from '~/store/menu/state'

const actions: ActionTree<MenuState, RootState> = {
  changePending({ commit }, value: boolean = false) {
    commit('setPending', value)
  },
  changeOpened({ commit }, value: boolean = false) {
    commit('setOpened', value)
  },
  toggleOpen({ commit }) {
    commit('setToggleOpen')
    return this.state.menu.opened
  },
}
export default actions
