
import { Component, Vue, Getter } from 'nuxt-property-decorator'

@Component
export default class TheHeader extends Vue {
  @Getter('menu/opened') isMobileMenuOpened!: boolean

  public head() {
    return {
      bodyAttrs: {
        class: this.isMobileMenuOpened ? 'overflow-hidden' : '',
      },
    }
  }

  public beforeClick() {
    this.$store.dispatch('menu/toggleOpen')
  }
}
