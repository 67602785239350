import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=d5ed871a&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsTheFooterNewsletter: require('/usr/src/nuxt-app/components/elements/TheFooterNewsletter.vue').default})
