import layoutState from '~/store/layout/state'
import layoutActions from '~/store/layout/actions'
import filerMutations from '~/store/layout/mutations'
import layoutGetters from '~/store/layout/getters'

export const namespaced = 'layout'

export { layoutState }
export { filerMutations }
export { layoutActions }
export { layoutGetters }
