import { GetterTree } from 'vuex'
import { RootState } from '~/store'
import { LayoutState } from '~/store/layout/state'

const getters: GetterTree<LayoutState, RootState> = {
  offset: (state) => state.offsetY,
  isSticky: (state) => state.offsetY > 53,
  width: (state) => state.deviceWidth,
  hasScrolled: (state) => state.scrolled,
  isMobile: (state) => state.deviceWidth < 992,
  breakpoint: (state) => {
    if (state.deviceWidth < 360) {
      return 'xxs'
    } else if (state.deviceWidth < 480) {
      return 'xs'
    } else if (state.deviceWidth < 640) {
      return 'sm'
    } else if (state.deviceWidth < 768) {
      return 'md'
    } else if (state.deviceWidth < 1024) {
      return 'lg'
    } else if (state.deviceWidth < 1280) {
      return 'xl'
    } else {
      return '2xl'
    }
  },
}

export default getters
