
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class ThePrivacy extends Vue {
  @Prop() value!: any
  @Prop({ default: 'text-xs leading-2.5' }) textClasses!: string

  toggle() {
    if (this.value?.privacy) {
      this.value.privacy.$model = !this.value.privacy.$model
    }
  }
}
