import { MutationTree } from 'vuex'
import { MenuState } from '~/store/menu/state'

const mutations: MutationTree<MenuState> = {
  setPending: (state, value: boolean = false) => (state.pending = value),
  setOpened: (state, value: boolean = false) => (state.opened = value),
  setToggleOpen: (state) => (state.opened = !state.opened),
}

export default mutations
