
import { Component } from 'nuxt-property-decorator'
import NewsletterValidator from '../classes/validations/newsletter'
import Newsletter from '../classes/newsletter'

@Component
export default class TheFooterNewsletter extends NewsletterValidator {
  public form: Newsletter = new Newsletter()

  public submit() {
    this.$v.form.$touch()
    if (this.$v.form.$invalid) {
      return
    }
    this.makeNewForm().then((res) => {
      this.$v.$reset()
    })
  }

  public async makeNewForm() {
    this.form = await new Newsletter()
  }
}
