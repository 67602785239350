import { render, staticRenderFns } from "./TheFooterNewsletter.vue?vue&type=template&id=d6a826c8&"
import script from "./TheFooterNewsletter.vue?vue&type=script&lang=ts&"
export * from "./TheFooterNewsletter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsThePrivacy: require('/usr/src/nuxt-app/components/elements/ThePrivacy.vue').default})
