import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03e2fe16 = () => interopDefault(import('../pages/agenzie-partner.vue' /* webpackChunkName: "pages/agenzie-partner" */))
const _f99a99b8 = () => interopDefault(import('../pages/scopri.vue' /* webpackChunkName: "pages/scopri" */))
const _6116136c = () => interopDefault(import('../pages/vendi-e-acquista.vue' /* webpackChunkName: "pages/vendi-e-acquista" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agenzie-partner",
    component: _03e2fe16,
    name: "agenzie-partner"
  }, {
    path: "/scopri",
    component: _f99a99b8,
    name: "scopri"
  }, {
    path: "/vendi-e-acquista",
    component: _6116136c,
    name: "vendi-e-acquista"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
