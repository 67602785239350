import { MutationTree } from 'vuex'
import { LayoutState } from '~/store/layout/state'

const mutations: MutationTree<LayoutState> = {
  setHasScrolled: (state) => (state.scrolled = true),
  setHasNotScrolled: (state) => (state.scrolled = false),
  setY: (state, value: number) => (state.offsetY = value),
  setWidth: (state, value: number) => (state.deviceWidth = value),
}

export default mutations
