export const AgenziePartnerTheAbout = () => import('../../components/agenziePartner/TheAbout.vue' /* webpackChunkName: "components/agenzie-partner-the-about" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerTheChooseYourPlan = () => import('../../components/agenziePartner/TheChooseYourPlan.vue' /* webpackChunkName: "components/agenzie-partner-the-choose-your-plan" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerTheClients = () => import('../../components/agenziePartner/TheClients.vue' /* webpackChunkName: "components/agenzie-partner-the-clients" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerTheContact = () => import('../../components/agenziePartner/TheContact.vue' /* webpackChunkName: "components/agenzie-partner-the-contact" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerTheHowDoesItWork = () => import('../../components/agenziePartner/TheHowDoesItWork.vue' /* webpackChunkName: "components/agenzie-partner-the-how-does-it-work" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerThePlan = () => import('../../components/agenziePartner/ThePlan.vue' /* webpackChunkName: "components/agenzie-partner-the-plan" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerTheSkeleton = () => import('../../components/agenziePartner/TheSkeleton.vue' /* webpackChunkName: "components/agenzie-partner-the-skeleton" */).then(c => wrapFunctional(c.default || c))
export const AgenziePartnerTheWelcome = () => import('../../components/agenziePartner/TheWelcome.vue' /* webpackChunkName: "components/agenzie-partner-the-welcome" */).then(c => wrapFunctional(c.default || c))
export const ClassesContact = () => import('../../components/classes/contact.ts' /* webpackChunkName: "components/classes-contact" */).then(c => wrapFunctional(c.default || c))
export const ClassesNewsletter = () => import('../../components/classes/newsletter.ts' /* webpackChunkName: "components/classes-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheAlert = () => import('../../components/elements/TheAlert.vue' /* webpackChunkName: "components/elements-the-alert" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheCarousel = () => import('../../components/elements/TheCarousel.vue' /* webpackChunkName: "components/elements-the-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheFooterNewsletter = () => import('../../components/elements/TheFooterNewsletter.vue' /* webpackChunkName: "components/elements-the-footer-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheLogoMask = () => import('../../components/elements/TheLogoMask.vue' /* webpackChunkName: "components/elements-the-logo-mask" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheMask = () => import('../../components/elements/TheMask.vue' /* webpackChunkName: "components/elements-the-mask" */).then(c => wrapFunctional(c.default || c))
export const ElementsThePrivacy = () => import('../../components/elements/ThePrivacy.vue' /* webpackChunkName: "components/elements-the-privacy" */).then(c => wrapFunctional(c.default || c))
export const HomeTheGoWithGeriko = () => import('../../components/home/TheGoWithGeriko.vue' /* webpackChunkName: "components/home-the-go-with-geriko" */).then(c => wrapFunctional(c.default || c))
export const HomeTheGraph = () => import('../../components/home/TheGraph.vue' /* webpackChunkName: "components/home-the-graph" */).then(c => wrapFunctional(c.default || c))
export const HomeTheImprovePerformance = () => import('../../components/home/TheImprovePerformance.vue' /* webpackChunkName: "components/home-the-improve-performance" */).then(c => wrapFunctional(c.default || c))
export const HomeTheNumbers = () => import('../../components/home/TheNumbers.vue' /* webpackChunkName: "components/home-the-numbers" */).then(c => wrapFunctional(c.default || c))
export const HomeTheSellAndBuy = () => import('../../components/home/TheSellAndBuy.vue' /* webpackChunkName: "components/home-the-sell-and-buy" */).then(c => wrapFunctional(c.default || c))
export const HomeTheTestimonials = () => import('../../components/home/TheTestimonials.vue' /* webpackChunkName: "components/home-the-testimonials" */).then(c => wrapFunctional(c.default || c))
export const HomeTheWelcome = () => import('../../components/home/TheWelcome.vue' /* webpackChunkName: "components/home-the-welcome" */).then(c => wrapFunctional(c.default || c))
export const LayoutTheFooter = () => import('../../components/layout/TheFooter.vue' /* webpackChunkName: "components/layout-the-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutTheHeader = () => import('../../components/layout/TheHeader.vue' /* webpackChunkName: "components/layout-the-header" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheAbout = () => import('../../components/scopri/TheAbout.vue' /* webpackChunkName: "components/scopri-the-about" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheFaq = () => import('../../components/scopri/TheFaq.vue' /* webpackChunkName: "components/scopri-the-faq" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheFaqs = () => import('../../components/scopri/TheFaqs.vue' /* webpackChunkName: "components/scopri-the-faqs" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheGuides = () => import('../../components/scopri/TheGuides.vue' /* webpackChunkName: "components/scopri-the-guides" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheSkeleton = () => import('../../components/scopri/TheSkeleton.vue' /* webpackChunkName: "components/scopri-the-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheWelcome = () => import('../../components/scopri/TheWelcome.vue' /* webpackChunkName: "components/scopri-the-welcome" */).then(c => wrapFunctional(c.default || c))
export const ScopriTheWhyUs = () => import('../../components/scopri/TheWhyUs.vue' /* webpackChunkName: "components/scopri-the-why-us" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheAbout = () => import('../../components/vendiEAcquista/TheAbout.vue' /* webpackChunkName: "components/vendi-e-acquista-the-about" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheArticles = () => import('../../components/vendiEAcquista/TheArticles.vue' /* webpackChunkName: "components/vendi-e-acquista-the-articles" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheChooseGerikoProgram = () => import('../../components/vendiEAcquista/TheChooseGerikoProgram.vue' /* webpackChunkName: "components/vendi-e-acquista-the-choose-geriko-program" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheHowToPartecipate = () => import('../../components/vendiEAcquista/TheHowToPartecipate.vue' /* webpackChunkName: "components/vendi-e-acquista-the-how-to-partecipate" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheHowToSell = () => import('../../components/vendiEAcquista/TheHowToSell.vue' /* webpackChunkName: "components/vendi-e-acquista-the-how-to-sell" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheNumbers = () => import('../../components/vendiEAcquista/TheNumbers.vue' /* webpackChunkName: "components/vendi-e-acquista-the-numbers" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheSkeleton = () => import('../../components/vendiEAcquista/TheSkeleton.vue' /* webpackChunkName: "components/vendi-e-acquista-the-skeleton" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheStep = () => import('../../components/vendiEAcquista/TheStep.vue' /* webpackChunkName: "components/vendi-e-acquista-the-step" */).then(c => wrapFunctional(c.default || c))
export const VendiEAcquistaTheWelcome = () => import('../../components/vendiEAcquista/TheWelcome.vue' /* webpackChunkName: "components/vendi-e-acquista-the-welcome" */).then(c => wrapFunctional(c.default || c))
export const ClassesValidationsContact = () => import('../../components/classes/validations/contact.ts' /* webpackChunkName: "components/classes-validations-contact" */).then(c => wrapFunctional(c.default || c))
export const ClassesValidationsExtractors = () => import('../../components/classes/validations/extractors.ts' /* webpackChunkName: "components/classes-validations-extractors" */).then(c => wrapFunctional(c.default || c))
export const ClassesValidationsNewsletter = () => import('../../components/classes/validations/newsletter.ts' /* webpackChunkName: "components/classes-validations-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ClassesValidationsValidators = () => import('../../components/classes/validations/validators.ts' /* webpackChunkName: "components/classes-validations-validators" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderMobileMenu = () => import('../../components/layout/header/MobileMenu.vue' /* webpackChunkName: "components/layout-header-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderTheHamburger = () => import('../../components/layout/header/TheHamburger.vue' /* webpackChunkName: "components/layout-header-the-hamburger" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderTheLogo = () => import('../../components/layout/header/TheLogo.vue' /* webpackChunkName: "components/layout-header-the-logo" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeaderTheMenu = () => import('../../components/layout/header/TheMenu.vue' /* webpackChunkName: "components/layout-header-the-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
