import { GetterTree } from 'vuex'
import { RootState } from '~/store'
import { MenuState } from '~/store/menu/state'

const getters: GetterTree<MenuState, RootState> = {
  pending: (state) => state.pending,
  opened: (state) => state.opened,
}

export default getters
