import { ActionTree } from 'vuex'
import { RootState } from '~/store'
import { LayoutState } from '~/store/layout/state'

const actions: ActionTree<LayoutState, RootState> = {
  changeHasScrolled({ commit }) {
    commit('setHasScrolled')
  },
  changeHasNotScrolled({ commit }) {
    commit('setHasNotScrolled')
  },
  changeY({ commit }, value: number) {
    commit('setY', value)
  },
  changeWidth({ commit }, value: number) {
    commit('setWidth', value)
  },
}
export default actions
