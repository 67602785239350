import menuState from '~/store/menu/state'
import menuActions from '~/store/menu/actions'
import menuMutations from '~/store/menu/mutations'
import menuGetters from '~/store/menu/getters'

export const namespaced = 'menu'

export { menuState }
export { menuMutations }
export { menuActions }
export { menuGetters }
